import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/greg/Desktop/projects/portfolio/src/components/page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Somehow I managed to graduate, wrap up my work on `}<a parentName="p" {...{
        "href": "/request"
      }}>{`request`}</a>{` and with the
`}<a parentName="p" {...{
        "href": "/ctg"
      }}>{`internship`}</a>{` under my belt, I got hired into a contracting position at Fitch
Ratings. Believe it or not, this job was actually easier to land than the
internship. That said, it was much more in depth and I finally started getting
my hands dirty...`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.fitchsolutions.com/fitch-connect"
      }}>{`Fitch Connect`}</a>{` was my main focus during my time here. They describe it as
such...`}</p>
    <blockquote>
      <p parentName="blockquote">{`Fitch Connect can intuitively and seamlessly help you manage risk more
efficiently and turn opportunities into realities.`}</p>
    </blockquote>
    <p>{`I was there during the early stages of this build out, around a time when they
were pulling it in-house after some initial outsourcing. This transition was
interesting to say the least. It was the first complex web application I dove
into, consisting of thousands of code written by a swath of different different
developers. Some bits were well written and documented while others had major
shortcomings.`}</p>
    <p>{`It was built with `}<a parentName="p" {...{
        "href": "https://backbonejs.org"
      }}><inlineCode parentName="a">{`backbone`}</inlineCode></a>{`, at a time when `}<inlineCode parentName="p">{`backbone`}</inlineCode>{` and other MVC
frameworks were still fairly prevalent, and `}<a parentName="p" {...{
        "href": "https://gruntjs.com/"
      }}><inlineCode parentName="a">{`grunt`}</inlineCode></a>{` was used for
concatenation / task management. The app contained...`}</p>
    <ul>
      <li parentName="ul">{`Search functionality and autocompletes.`}</li>
      <li parentName="ul">{`Data tables containing risk data.`}</li>
      <li parentName="ul">{`Forms and advanced filtering options.`}</li>
      <li parentName="ul">{`Account management and portfolio construction.`}</li>
    </ul>
    <p>{`We fixed a seemingly insurmountable list of bugs, added new features,
implemented new designs, and more. I got to work with folks of all ages and
backgrounds ranging from the ui/ux and backend teams to quality assurance
folks and product managers. Towards the end of my stint here, I spent a fair
amount of time working out of one of their offices in lower manhattan with a
bunch of the product management team.`}</p>
    <p>{`I have no idea where the application is now, but at the very least it does seem
to still be `}<a parentName="p" {...{
        "href": "https://www.fitchsolutions.com/fitch-connect"
      }}>{`alive`}</a>{`. As a non-finance person, I can't say I loved the tool
itself but diving into this codebase was definitely a game changer for me as a
programmer. Aside from exposure to building and maintaining all of the
above-mentioned features, I got experience with things like custom user
analytics and insight into what managing a complex piece of software is like.`}</p>
    <p>{`I met a lot of cool folks at fitch, some who I've stayed in contact with, and
learned a lot. My `}<a parentName="p" {...{
        "href": "/qintel"
      }}>{`next company`}</a>{` was a big jump as I went from a well
established financial company to a tiny cyber security firm that was only just
starting it's journey building software products.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      